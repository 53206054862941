import { render, staticRenderFns } from "./sap.vue?vue&type=template&id=40d44408&scoped=true&"
import script from "./sap.vue?vue&type=script&lang=js&"
export * from "./sap.vue?vue&type=script&lang=js&"
import style0 from "./sap.vue?vue&type=style&index=0&id=40d44408&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d44408",
  null
  
)

export default component.exports